@import './variable.less';

.pointer {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.item-hover {
  transition: all 0.2s ease;
  transform: scale(1);

  &:hover {
    background-color: fade(@primary-color, 10);
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 fade(@black, 20);
  }
}

.ant-checkbox-inner {
  border-radius: 4px;
}

.card-hover {
  transition: all 0.3s ease;
  transform: scale(1);

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.45);
    transform: scale(1.05);
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;

  [dir='rtl'] & {
    text-align: right;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;

  [dir='rtl'] & {
    text-align: left;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.cr-btn {
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}

.cr-btn-circle {
  background-color: transparent;
  border-color: transparent;
  color: @grey-500;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: fade(@tooltip-bg, 4);
    border-color: fade(@tooltip-bg, 4);
    color: @text-color;
  }

  & .anticon {
    font-size: 20px;
  }
}

.btn-secondary {
  background-color: @secondary-color;
  border-color: @secondary-color;
  color: @white;

  &:hover,
  &:focus {
    background-color: darken(@secondary-color, 25);
    border-color: darken(@secondary-color, 25);
    color: @white;
  }
}

.btn-light-blue {
  background-color: fade(@primary-color, 10);
  color: @primary-color;
  border-color: fade(@primary-color, 10);

  &:hover,
  &:focus {
    background-color: fade(@primary-color, 20);
    color: @primary-color;
    border-color: fade(@primary-color, 20);
  }
}

.btn-secondary-outline {
  background-color: transparent;
  border-color: @secondary-color;
  color: @secondary-color;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: darken(@secondary-color, 15);
    color: darken(@secondary-color, 15);
  }
}

.btn-primary-outline {
  background-color: transparent;
  border-color: @primary-color;
  color: @primary-color;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: darken(@primary-color, 15);
    color: darken(@primary-color, 15);
  }
}

.btn-white-outline {
  background-color: transparent;
  border-color: @white;
  color: @white;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: @white;
    color: @white;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.dot {
  height: 12px;
  width: 12px;
  display: block;
  border-radius: @border-radius-circle;
}

.cr-dropdown-link {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: @border-radius-circle;
  color: fade(@tooltip-bg, 84) !important;
  font-size: 20px;

  &:hover {
    background-color: fade(@tooltip-bg, 10);
  }
}

.close-btn {
  background-color: transparent;
  box-shadow: none;
  border: 0 none;
  padding: 0;
  height: auto;

  &:hover,
  &:focus {
    border: 0 none;
  }

  & .anticon {
    font-size: 20px;
  }
}

.page-title {
  color: @text-color;
  font-weight: @font-weight-bold;
  margin-bottom: 16px;
  font-size: @font-size-lg;
  display: inline-block;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 12px;
}

.ant-modal {
  max-width: 90%;
}

.text-secondary {
  color: @text-color-secondary;
}

.text-green {
  color: @green-6;
}

.slick-slider-global .slick-dots li {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;

  & button {
    width: 12px;
    height: 12px;
    padding: 0;
  }

  & button::before {
    width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    color: #be8658;
  }
}

.slick-slider-global .slick-dots li.slick-active button::before {
  color: #825a44;
  opacity: 0.75;
}

.z-index-20 {
  z-index: 20;
}

.ant-picker {
  padding-top: @input-padding-vertical-base;
  padding-bottom: @input-padding-vertical-base;
}

.ant-pagination {
  & li {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  &.mini {
    & .ant-pagination-item,
    & .ant-pagination-prev,
    & .ant-pagination-next {
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }
}

.ant-drawer,
.ant-modal-wrap,
.ant-modal-mask,
.ant-picker-dropdown,
.ant-select-dropdown,
.ant-message {
  z-index: 1051;
}

.ant-drawer-close {
  color: @text-color-secondary;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 11px;
  right: 6px;
  padding: 5px;

  &:hover,
  &:focus {
    background-color: fade(@black, 5);
    color: @text-color;
  }
}

// steps

.ant-steps-vertical {
  .ant-steps-item {
    background: #f7f9fc;
    padding: 10px 8px !important;
    border-radius: 10px;
    margin-bottom: 8px;

    .ant-steps-item-container {
      display: flex;
      align-items: center;
      .ant-steps-item-icon {
        height: 30px;
        width: 30px;

        .ant-steps-icon {
          font-size: 16px;
          line-height: 2;
        }
      }
      .ant-steps-item-tail::after {
        height: 0;
        width: 0;
      }
    }
  }
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: 0px;
}

// center a component
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  .ant-card-head {
    border-bottom: 1.5px solid #d3d8e6 !important;
    background: #fff;
  }
}

.app-header-card {
  border-radius: 0;

  .ant-card-body {
    padding: 12px !important;
  }
}

.ant-input-password,
.ant-picker {
  height: 40px;
}

.ant-input-wrapper,
.ant-input-group {
  .ant-input {
    height: 40px;
  }
}

// margin top
.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 2em;
}

.cicon {
  font-size: 1.5em;
  cursor: pointer;
}

.ant-input-lg {
  font-size: 14px;
}
// required

.required:before {
  content: '* ';
  color: #ff4d4f;
}

// Ant Tooltip
.ant-tooltip-inner {
  & a {
    color: @text-color-white;
    display: flex;
    align-items: center;

    & .ant-menu-item-icon {
      margin-right: 6px;

      & .anticon,
      & svg {
        display: block;
      }
    }
  }
}

.ant-badge-count {
  right: -8px;
}

.notify-header-message {
  .ant-list-item-meta {
    border-bottom: 1px solid #979797;
    padding-bottom: 1rem;
  }
  .notify-listItem {
    padding-left: 30px !important;
    padding-right: 0 !important;
  }

  .not-seen {
    background: #f3f2f2;
    padding: 1em;
    padding-left: 0;
    border-bottom: 0.5px #979797;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .notify-listItem:last-child .ant-list-item-meta {
    border-bottom: none !important;
  }

  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    top: -8px;
  }
  .ant-list-item-meta-description {
    color: #303030;
  }
  .time {
    font: 0.754rem;
    color: #737373;
    padding-top: 0.3rem;
  }
  .notify-header-message .ant-dropdown-menu-item.header {
    border-bottom: 1px solid #979797;
  }
  .notify-header-message .ant-dropdown-menu-item {
    padding-top: 1rem !important;
  }

  .ant-collapse-content-box {
    padding-bottom: 0;
  }

  .ant-collapse-borderless {
    background-color: inherit;
  }

  .ant-collapse-borderless > .ant-collapse-item:last-child,
  .ant-collapse-borderless
    > .ant-collapse-item:last-child
    .ant-collapse-header {
    border-bottom: none;
  }

  .ant-collapse-header {
    background-color: inherit;
    padding: 0 !important;
  }
  .text {
    padding: 1rem;
    padding-bottom: 0;
  }

  .notify-scroll-submenu {
    height: 500px;
  }
}

.ant-btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem !important;
}

.ant-drawer-content-wrapper {
  width: 70% !important;
  min-width: 360px;
  max-width: 720px;
}

.ant-select-multiple {
  overflow: hidden;
}

.assigned-location-modal {
  .ant-modal-body {
    padding: 0;
  }
}

@media screen and (min-width: 576px) {
  .notify-header-message {
    width: 40vw !important;
    min-width: 300px;
    max-width: 566px;
  }
}
